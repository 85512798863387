import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Svg from "../../../images/corona-island/error.svg";
import styles from "./error.module.scss";
import "../../../styles/corona-island.scss";

const Error = () => {

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        
        <img src={Svg} alt='error'/>
        <h1>WOOPS</h1>
        <h3>Página no encontrada, intenta otros links de búsqueda.</h3>

        <AniLink
          to={'/islacorona'}
          cover
          direction="left"
          duration={0.5}
          bg="#ffc311"
          className={styles.btn}
        >
          VOLVER
        </AniLink>
      </div>
    </section>
  );
};

export default Error;
